import React from "react";
// components
import { DefaultLayout } from "../components/layout/defaultLayout";
import { PageHeading } from "../components/heading/pageHeading";
import { BreadcrumbBar } from "../components/bar/breadcrumbBar";
import { PreparationSection } from "../components/section/preparationSection";
// breadcrumb
const breadcrumbs = [
  { txt: "トップ", url: "/" },
  { txt: "TOFUについて", url: "/about" },
];

const AboutPage = () => {
  return (
    <DefaultLayout pageTitle="TOFUについて">
      <PageHeading enTxt="ABOUT" jpTxt="TOFUについて" />
      <BreadcrumbBar breadcrumbs={breadcrumbs} />
      <PreparationSection txt="ABOUT" />
    </DefaultLayout>
  );
};

export default AboutPage;
